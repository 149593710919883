<template>
  <v-card class="mx-auto pa-5">
    <v-card-title class="mx-0 px-0">Detail CustomerRank</v-card-title>
    <v-row no-gutters>
      <div class="form-detail">
          <v-row>
          <v-col cols="4" md="5">
            <v-row no-gutters>
              <v-col cols="5">
                <v-card-text class="py-1">Name:</v-card-text>
              </v-col>
              <v-col cols="6">
                <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{item.name? item.name : ''}}</v-card-text>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-row>
    <v-card-actions class="mx-0 pa-0">
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="onClose">Close</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: 'DetailCustomerRank',
  props: ['selectedItem', 'onClose'],
  data: () => ({
    item: {}
  }),
  watch: {
    selectedItem: {
      handler() {
        if (this.selectedItem) this.item = this.selectedItem;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style scoped>
.form-detail {
  display: block;
  width: 100%;
  border: 1px solid #ddd;
}

.title-detail {
  padding: 15px 15px 0 15px;
  margin-bottom: 0px;
}

.form-detail >>> .v-subheader {
  height: 32px;
}
</style>
